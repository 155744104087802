@font-face {
  font-family: 'FuturaPTLight';
  src: url('./fonts/FuturaPTLight.otf') format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'FuturaPTMedium';
  src: url('./fonts/FuturaPTMedium.otf') format('truetype');
  font-weight: 300;
}
body {
  margin: 0;
  font-family: 'FuturaPTMedium', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media (min-width: 1200px){
  .container, .container-lg, .container-md, .container-sm, .container-xl {
      max-width: 1500px !important;
  }
}
form {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
}

button:focus{
  outline: 0 !important;
  box-shadow: none !important;
}

/* Input style */
.input-container {
  position: relative;
  display: flex;
  flex-direction: column;
}
input:focus{
  outline: 0 !important;
}

.input-container:focus-within label {
  transform: translate(0, 0) scale(0.6);
  color: #000;
}

.input-container .filled {
  transform: translate(0, 0) scale(0.6);
}

.input-container label {
  position: absolute;
  pointer-events: none;
  transform: translate(0, 23px) scale(1);
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  color: #000;
  font-size: 30px;
  font-weight: 400;
  line-height: 1;
  left: 0;
}

.input-container input {
  height: 64px;
  border-radius: 4px;
  border: none;
  border-bottom: 2px solid #000;
  background:transparent;
  border-radius:0;
  padding: 24px 0 4px 0;
  font-size: 16px;
  line-height: 1;
  outline: none;
  box-shadow: none;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.input-container input:focus {
  box-shadow: none;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input[type="checkbox"] {
  appearance: none;
  background-color: #FF4B43;
  margin: 0;
  font: inherit;
  color: var(--green-dark);
  width: 28px;
  height: 28px;
  border: 0.15em solid #000;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--green-dark);
  /* Windows High Contrast Mode */
  background-color: CanvasText;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

.fakecheckbox {
  display: flex;
  gap:15px;
  color:var(--green-dark);
  align-items: center;
  margin-top: 25px;
}

.fakecheckbox a{
  color: #fff;
  margin-left: -9px;
}
.fakecheckbox a:hover{
  color: #fff;
}
.btn-secondary{
  background-color: #fff !important;
  color: #000 !important;
  font-size: 20px !important;
  font-weight: 400 !important;
  border-radius: 50px !important;
  padding: 15px 80px !important;
  border: 0 !important;
  text-align: center;
  line-height: 1.2 !important;
}
.btn-primary{
  background-color: #FF4B43 !important;
  color: #000 !important;
  font-size: 20px !important;
  font-weight: 400 !important;
  border-radius: 50px !important;
  padding: 15px 80px !important;
  border: 0 !important;
  text-align: center;
  line-height: 1.2 !important;
}
.link-text{
  text-decoration: underline;
  color: #fff;
  line-height: 1.2 !important;
}
.link-text:hover{
  text-decoration: underline;
  color: #fff;
}
h1{
  font-size: 60px !important;
}
h3{
  font-size: 40px !important;
}
/* HEADER */
.navbar-header{
  background-color: #FF4B43;
  height: 120px;
}
.header-logo{
  width: 150px;
  height: 100px;
  object-fit: contain;
}
.header-navbar{
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  float: right;
  align-items: center;
}
.header-navbar-item{
  margin: 0 15px;
}
.header-navbar-item a{
  color: #000;
  text-decoration: none;
  font-weight: 400;
}
.header-navbar-item.active a{
  color: #fff;
}
.header-navbar-item a:hover{
  color: #fff;
  text-decoration: none;
}
/* FOOTER */
.navbar-footer{
  padding-top: 0 !important;
}
.navbar-footer .row{
  width: calc(100% + 30px);
  height: 250px;
}
.navbar-footer .footer-border-top{
  border-top: 1px solid #939090;
  height:20px !important;
}
.navbar-footer .col-md-3{
  display:flex;
  align-items: center;
  height: 190px;
}
.navbar-footer .col-md-12{
  height:60px;
}
.footer-navbar{
  list-style: none;
  padding:0;
  margin:0;
}
.footer-navbar-item:first-child{
  margin-bottom:20px;
}
.footer-navbar-item a{
  color: #939090;
  font-weight: 400;
  text-decoration: none;
}
.footer-navbar-item a:hover{
  color: #939090;
  font-weight: 400;
  text-decoration: none;
}
.footer-bottom{
  width:100%;
  height: 60px;
}
.footer-bottom p{
  float:left;
  margin:0;
  padding: 20px 0;
  color: #939090;
  line-height: 1.2;
}
.footer-bottom p a{
  color: #939090;
  text-decoration: underline;
  font-weight:600;
}
.footer-bottom p a:hover{
  color: #939090;
  text-decoration: underline;
  font-weight:600;
}
.footer-bottom .footer-logo{
  float:right;
  height: 64px;
  padding: 20px 0;
}
/* HOME */
.home-banner{
  min-height: calc(100vh - 120px);
  background-color: #FF4B43;
  display: flex;
  align-items: center;
  padding: 40px 0;
}
.home-banner .col-md-7{
  display: flex;
  align-items: center;
}
.home-banner h1{
  color: #000;
  margin-bottom: 40px;
  font-weight: 400;
}
.home-banner .link-text{
  margin-left: 40px;
}
.home-card{
  width:100%;
}
.home-intro{
  min-height: calc(100vh - 120px);
  display: flex;
  align-items: center;
  padding: 40px 0;
}
.home-intro h2{
  color: #000;
  margin-bottom: 40px;
  font-weight: 400;
}
.home-intro p{
  margin-bottom: 40px;
  font-size: 18px;
  font-family: 'FuturaPTLight', sans-serif !important;
}
.blood-icon-container{
  height: 100%;
  display: flex;
}
.blood-icon-inner-container{
  position: relative;
  width: fit-content;
  margin: auto;
  height: 500px;
  width: 500px;
}
.red-circle{
  position:absolute;
  top:-200px;
  right: -30px;
  width: 280px;
  height: 280px;
  background: #FF4B43;
  border-radius: 50%;
  z-index: -1;
}
.blood-icon{
  height: 330px;
  position: absolute;
  top: -100px;
}
.home-image-1{
  width: 500px;
  height: 500px;
  object-fit: contain;
  position: absolute;
  top: -200px;
  z-index: 11;
}
.home-image-2{
  width: 500px;
  height: 500px;
  object-fit: contain;
  position: absolute;
  top: -150px;
  z-index: 10;
}
.home-image-3{
  width: 500px;
  height: 500px;
  object-fit: contain;
  position: absolute;
  top: -100px;
  z-index: 9;
}
/* CARD */
.card-banner{
  min-height: calc(100vh - 120px);
  background-color: #FF4B43;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
}
.card-input-container{
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-btn-container{
  display: flex;
  justify-content: right;
  align-items: center;
  width: 740px;
  margin: auto;
  margin-top: 40px;
}
.card-btn-container button{
  margin-left: 20px;
}
.card-user-number{
  display:grid;
  width: 300px;
  margin: 0 20px;
}
.card-user-number.validate-pin-container{
  width: 100%;
  margin: 0;
  margin-bottom: 20px;
}
.card-user-number label{
  font-size: 16px;
  font-weight: 400;
  color:#000;
}
.card-user-number input{
  font-size: 30px;
  width: 100%;
  background: transparent;
  color: #fff;
  font-weight: 400;
  border:0;
  border-bottom: 2px solid #000;
}
.card-user-number.validate-pin-container input{
  color: #000;
}
.card-user-number input::placeholder{
  color: #fff;
}
.card-user-number.validate-pin-container input::placeholder{
  color: #000;
}
.card-pin-number{
  width: 400px;
  margin: 0 20px;
  display:flex;
  align-items: center;
  position:relative;
}
.card-pin-number label{
  font-size: 30px;
  font-weight: 400;
  color:#000;
  width: 200px;
  margin:0;
}
.card-pin-number input{
  border: 2px solid #000;
  font-size:30px;
  width: 45px;
  height: 64px;
  z-index: 99;
  background:transparent;
  text-align: center;
  color: #000;
  margin: 0 5px;
  padding:0;
  overflow: hidden;
}
.card-pin-number input:focus{
  outline:0;
}
/* QUESTION */
#question-form{
  margin-top: -44px;
}
#question-form .input-container{
  margin-top: 20px;
}
#question-form .btn-secondary{
  width: fit-content;
  margin-top: 20px;
}
.question-text{
  width:50%;
  font-weight: 400;
  font-size: 18px;
  color:#000;
  line-height: 1.2;
}
/* LOGIN */
#login-form{
  margin-top: -44px;
}
#login-form .input-container{
  margin-top: 20px;
}
#login-form .btn-secondary{
  width: fit-content;
  margin-top: 20px;
}
.login-text{
  width:50%;
  font-weight: 400;
  font-size: 18px;
  color:#000;
}
.login-text a{
  color: #fff;
  text-decoration: underline;
}
.btn-scroll{
  color: #000;
  font-weight: bold;
  font-size: 16px;
  background: transparent;
  border: 0;
  transform: rotate(-90deg);
  position: absolute;
  bottom: 90px;
  right: -50px;
  display: flex;
  align-items: center;
}
.btn-scroll:focus{
  outline:0;
}
.btn-scroll div{
  width: 120px;
  height: 1px;
  background: #000;
  margin-left: 10px;
}
.demo { 
  width: 535px;
  height: 348px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  perspective: 2000px;
  position: relative;
  z-index: 1;
  transform: translate3d(0.1px, 0.1px, 0.1px );
  flex-direction: row;
  justify-content: center;
}
.demo .card{
  box-shadow: inset 0 0 0 1px rgba(white,0.4), 0 25px 15px -10px rgba(0, 0, 0, 0.5);
  animation: none;
}
.demo .card {
  width:100%;
  height:100%;
  position: relative;
  overflow: hidden;
  overflow: hidden;
  z-index: 10;
  touch-action: none;
  box-shadow: rgb(0 0 0 / 25%) 0px 54px 55px, rgb(0 0 0 / 12%) 0px -12px 30px, rgb(0 0 0 / 12%) 0px 4px 6px, rgb(0 0 0 / 17%) 0px 12px 13px, rgb(0 0 0 / 9%) 0px -3px 5px;
  
  transition: transform 0.5s ease, box-shadow 0.2s ease;
  will-change: transform, filter;
  
  background-color: #FF4B43;
  transform-origin: center;
  
}
.demo .card:hover {
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
}
.demo .card.active,
.demo .card:hover {
  animation: none;
  transition: box-shadow 0.1s ease-out;
}
.demo .card.animated {
  transition: none;
  animation: holoCard 12s ease 0s 1;
}
.demo .card .card-inner{
  width:100%;
  height:100%;
  background-size: contain;
}
@keyframes holoCard {
  0%, 100% {
    transform: rotateZ(0deg) rotateX(0deg) rotateY(0deg);
  }
  5%, 8% {
    transform: rotateZ(0deg) rotateX(6deg) rotateY(-20deg);
  }
  13%, 16% {
    transform: rotateZ(0deg) rotateX(-9deg) rotateY(32deg);
  }
  35%, 38% {
    transform: rotateZ(3deg) rotateX(12deg) rotateY(20deg);
  }
  55% {
    transform: rotateZ(-3deg) rotateX(-12deg) rotateY(-27deg);
  }
}
#created-card{
  box-shadow: 0px 8px 15px #0000004D;
  width: 535px;
  transition: all 0.5s ease-in-out;
}
#created-card .card-top{
  background:#FF4B43;
  padding: 20px;
}
#created-card .card-logo{
  width: 200px;
}
#created-card .card-top p{
  margin: 0;
  margin-top: 30px;
  font-size: 22px;
  color: #000;
  font-weight: 400;
  font-family: 'FuturaPTMedium', sans-serif !important;
}
#created-card .card-top h1{
  color: #fff;
  line-height: 1.1;
  font-size: 34px !important;
  font-weight: 400;
  margin: 0;
  font-family: 'FuturaPTMedium', sans-serif !important;
}
#created-card .card-bottom{
  padding: 40px 20px;
  background: #fff;
}
#created-card .card-bottom p{
  margin: 0;
  font-size: 24px;
  line-height: 1.1;
}
#created-card .card-bottom p b{
  font-family: 'FuturaPTMedium', sans-serif !important;
  font-weight:bold;
}
.btn-edit-card{
  position: absolute;
  right: 0;
  color: #FF4B43;
  text-decoration: underline;
}
.btn-edit-card:hover{
  color: #FF4B43;
}
.card-right-container .btn-secondary{
  width: 50%;
  padding: 15px 0 !important;
  text-decoration: underline;
  font-size: 18px !important;
}
.card-right-container .btn-primary{
  width: 50%;
  padding: 15px 20px !important;
  font-size: 18px !important;
}
/* The flip card container - set the width and height to whatever you want. We have added the border property to demonstrate that the flip itself goes out of the box on hover (remove perspective if you don't want the 3D effect */
.flip-card {
background-color: transparent;
width: 535px;
height: 412px;
perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
position: relative;
width: 100%;
height: 100%;
transition: transform 0.8s;
transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front, .flip-card-back {
position: absolute;
width: 100%;
height: 100%;
-webkit-backface-visibility: hidden; /* Safari */
backface-visibility: hidden;
}

/* Style the back side */
.flip-card-back {
transform: rotateY(180deg);
}
#created-card-back{
  height: 100%;
  padding: 20px;
  box-shadow: 0px 8px 15px #0000004D;
  background: #fff;
}
#created-card-back label{
  margin:0;
}
#created-card-back h4{
  margin-bottom:40px;
}
#created-card-back .card-info-container{
  border-bottom: 1px solid #ddd;
  padding: 5px 0;
}
#created-card-back .card-info-container label{
  font-weight: 300;
  font-size: 18px;
}
#created-card-back .card-info-container span{
  font-size:20px;
  font-weight: bold;
  margin-left: 5px;
}
#created-card-back .card-info-container-reason{
  padding: 5px 0;
  height: 122px;
  border-bottom: 1px solid #ddd;
  position:relative;
}
#created-card-back .card-info-container-reason:after{
  content: '';
  height: 40px;
  width: 100%;
  position: absolute;
  top: 40px;
  left: 0;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
#created-card-back .card-info-container-reason label{
  font-size:20px;
  font-weight:300;
}
#created-card-back .card-info-container-reason span{
  font-size:20px;
  font-weight:bold;
  line-height: 40px;
}
#created-card-back .label-red{
  color: #FF4B43;
  font-weight: bold !important;
  font-size:16px;
}
#created-card-back .label-gray{
  color: #939090;
  font-weight: 300;
  font-size: 14px;
}
#created-card-back .label-gray a{
  color: #939090;
  font-weight: normal;
}
#created-card-back .card-info-logo{
  padding-left: 40px;
  display: flex;
  align-items: end;
}
#created-card-back .card-info-logo img{
  width:100%;
}
#recover-pin-modal .btn-close{
  display:none;
}
#recover-pin-modal .modal-footer button{
  width:50%;
  margin: 0;
}
#recover-pin-modal .card-user-number{
  margin: 0;
  width: 100%;
}
#recover-pin-modal .card-user-number input{
  color: #000;
}
#recover-pin-modal .card-user-number input::placeholder{
  color: #939090;
}
.link-text-open-modal{
  font-size:16px !important;
  font-weight:normal !important;
  color:#fff !important;
  text-decoration: underline;
  padding:0 !important;
  margin:0;
}
.card-error p{
  width: 740px;
  height: 64px;
  margin: auto;
  background: #fff;
  margin-bottom: 20px;
  border-radius: 10px;
  padding: 20px;
  color: #FF4B43;
  font-weight: bold;
}
.showMobile{
  display:none !important;
}
.open-menu-mobile{
  display:none !important;
}
.error-message{
  color: #fff;
  margin: 0;
  position: absolute;
  bottom: -30px;
}
.error-message.error-message-pin{
  color: #FF4B43;
  bottom: 7px;
}
.error-message.error-message-terms{
  color: #fff;
  margin: 0;
  position: absolute;
  bottom: unset !important;
}
#messageTextarea{
  background:transparent;
  border:0;
  border-bottom: 2px solid #000;
  margin-top:15px;
  padding-top: 15px;
  padding-bottom: 10px;
  height:49px;
}
#messageTextarea:focus{
  outline:0;
}
.hide-form{
  display:none;
}
.form-success{
  display:flex !important;
  align-items: center;
  justify-content: center;
}
.success-text{
  margin: 0;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
}
.form-success .btn-secondary{
  width:100%;
  margin-top:20px;
}
#falseinput-container{
  margin-top: 30px;
}
#falseinput{
  background-color: #fff !important;
  color: #000 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  border-radius: 50px !important;
  padding: 10px 30px !important;
  border: 0 !important;
  text-align: center;
  line-height: 1.2 !important;
  margin-right: 20px;
}
.phone-img-container{
  display:flex;
  align-items: center;
  justify-content: center;
}
.phone-img{
  width: 180px;
  z-index: 99;
  transform: rotate(6deg);
  margin-right: 30px;
}
.phone-img-circle{
  width: 270px;
  height: 270px;
  background-color: #fff;
  border-radius: 50%;
  position:absolute;
  margin-top: 60px;
}
#webapp-page{
  padding: 80px 0;
}
.OS-selector{
  margin:auto;
  width: fit-content;
}
.OS-selector button{
  height: 70px;
  width: 180px !important;
  background-color:#fff !important;
  border: 1px solid #FF4B43 !important;
  font-size: 24px !important;
  font-weight: 500 !important;
  border-radius: 0 !important;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.OS-selector button img{
  margin-right:10px;
}
.OS-selector button.active{
  color: #fff !important;
  background-color: #FF4B43 !important;
}
.OS-selector .android-selector{
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  border-right: 0 !important;
}
.OS-selector .apple-selector{
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
.os-steps .icon-circle{
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FF4B43;
  border-radius: 50%;
  margin: auto;
}
.os-steps .circle-img{
  width: 200px;
  height: 200px;
  margin: auto;
}
.os-steps .step-col h3{
  color: #FF4B43;
  font-size: 40px;
  font-family: 'FuturaPTLight', sans-serif !important;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 0px;
}
.os-steps .step-col p{
  font-size: 25px;
  font-weight: 500;
  text-align: center;
  line-height: 1.2;
}
.os-steps .step-col{
  text-align:center;
}
.os-steps .os-steps-title{
  font-size:24px;
  text-align:center;
  font-family: 'FuturaPTLight', sans-serif !important;
  line-height: 1.2;
  margin-top: 20px;
  margin-bottom: 80px;
}

.page-container{
  padding: 40px 0;
}

@media screen and (max-width: 600px) { 
  .card-btn-container{
    width: 100%;
    justify-content: left;
  }
  .card-btn-container .btn-secondary{
    width: 50%;
  }
  .card-btn-container .btn-secondary{
    margin:0;
  }
  .card-btn-container .link-text-open-modal{
    margin-left:15px;
    padding-left: 0 !important;
  }
  .card-input-container{
    display:block;
  }
  .card-user-number{
    margin:0;
    width: 100%;
  }
  .card-pin-number{
    width: 100%;
    margin: 0;
    margin-top: 20px;
    display: block;
  }
  .card-pin-number label{
    width:100%;
  }
  .card-right-container{
    width:100% !important;
  }
  .card-right-container .btn-primary{
    width:50% !important;
    margin:0 !important;
  }
  .card-right-container .btn-secondary{
    padding: 0 10px !important;
  }
  .home-intro{
    display:block;
    height: auto !important;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .home-intro .btn-primary{
    width: 70%;
    margin-left: 15%;
  }
  .home-intro h2 {
      margin-top: 40px;
      font-size: 25px;
  }
  .btn-primary{
    padding: 10px 20px !important;
  }
  .btn-secondary{
    padding: 10px 20px !important;
  }
  .showMobile{
    display:block !important;
  }
  .hideMobile{
    display:none !important;
  }
  .modal{
    width: 100vw !important;
    height: 100vh !important;
  }
  body{
    padding-right:0 !important;
  }
  .header-navbar{
    display:none;
  }
  .menu-mobile{
    position: fixed;
    top: 0;
    left: 110%;
    width: calc(100vw - 20%);
    height: 100vh;
    background: #fff;
    z-index: 9999;
    padding: 20px;
    padding-top: 80px;
    transition: all 0.5s ease-in-out;
    box-shadow: rgb(0 0 0 / 25%) 0px 54px 55px, rgb(0 0 0 / 12%) 0px -12px 30px, rgb(0 0 0 / 12%) 0px 4px 6px, rgb(0 0 0 / 17%) 0px 12px 13px, rgb(0 0 0 / 9%) 0px -3px 5px;
    -webkit-transform: translate3d(0,0,10px);
  }
  
  .menu-mobile ul{
    list-style:none;
    padding:0;
  }
  .menu-mobile ul .header-navbar-item{
    margin:0;
    text-align: right;
    font-size:30px;
    font-weight: 400;
  }
  .menu-mobile ul .header-navbar-item a{
    line-height: 1;
    display: block;
    margin-bottom: 20px;
  }
  .menu-mobile ul .header-navbar-item a:hover{
    color:#aaa;
  }
  
  .header-navbar-item.active a{
    color: #FF4B43;
  }
  .open-menu-mobile{
    display: grid !important;
    border:0;
    background:transparent;
  }
  .close-menu-mobile{
    position: absolute;
    right: 20px;
    top: 20px;
    display: grid;
    border:0;
    background:transparent;
  }
  .open-menu-mobile:focus,
  .close-menu-mobile:focus{
    outline:0;
  }
  .open-menu-mobile img,
  .close-menu-mobile img{
    margin:auto;
  }
  .open-menu-mobile span{
    font-size:10px;
    color:#000;
  }
  .close-menu-mobile span{
    font-size:10px;
    color:#B1A1A1;
  }
  .home-banner{
    height: fit-content;
  }
  .home-banner .col-md-7 h1{
    font-size: 30px !important;
  }
  .home-banner .col-md-5 .showMobile{
    display: flex !important;
    position:relative;
    z-index: 11;
  }
  .home-banner .col-md-5 .showMobile a:first-child{
    width: 60%;
  }
  .home-banner .col-md-5 .showMobile a:last-child{
    width: 40%;
  }
  .home-banner .link-text{
    margin-left:0;
    display: flex;
    align-items: center;
  }
  .demo{
    width: 100%;
    height: calc((100vw - 30px) / 1.55);
    margin-bottom: 40px;
  }
  .demo .card-inner{
    background-size: contain;
  }
  .btn-scroll{
    display:none;
  }
  .navbar-footer .row{
    width: auto;
    height: auto;
  }
  .navbar-footer .col-md-3{
    height: auto;
  }
  .navbar-footer .footer-border-top{
    border:0;
  }
  .navbar-footer .footer-border-top div{
    border-top: 1px solid #939090;
  }
  .navbar-footer .footer-menu-item a{
    font-size:18px;
    color: #939090;
    display:block;
    margin: 5px 0;
    font-weight: 400;
  }
  .navbar-footer .footer-bottom{
    padding:0 16px;
  }
  .navbar-footer .footer-bottom .footer-logo{
    float: unset;
    height: 24px;
    margin-top: 40px;
    padding:0;
  }
  .navbar-footer .footer-bottom p{
    float: unset;
    padding-top:10px;
  }
  .navbar-header{
    height: 100px;
  }
  .header-logo{
    height: 80px;
  }
  .question-text {
    width: 100%;
  }
  .home-banner {
    padding-bottom:40px;
  }
  .home-image-1,
  .home-image-2,
  .home-image-3{
    top: 0 !important;
    width: 200px;
    height: 200px;
  }
  .blood-icon-inner-container{
    width: 200px;
    height: 200px;
  }
  #question-form .input-container{
    margin-top: 0px !important;
  }
  #question-form .input-container:first-child{
    margin-top: 40px !important;
  }
  .os-steps .step-col:not(:last-child){
    margin-bottom:80px;
  }
  #webapp-page .btn-primary{
    width: 250px;
  }

}

@media screen and (min-width: 601px) and (max-height: 800px) { 
  
}